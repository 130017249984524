<template>
  <Trans :keypath="keypath" :tag="tag" :class="classes">
    <DynamicPopup
      wrapper-classes="!inline cursor-pointer underline decoration-gray-400"
    >
      <template #trigger> {{ t(label) }}</template>
      <template #content>
        <InfoFrame
          class="min-w-[280px] sm:min-w-[315px] max-w-[350px] sm:max-w-[450px]"
        >
          <p
            class="text-sm font-deco-alt leading-xs text-[var(--thm-info-popup-text)]"
          >
            {{ t(hint) }}
          </p>
        </InfoFrame>
      </template>
    </DynamicPopup>
  </Trans>
</template>

<script setup lang="ts">
import { DynamicPopup } from '@/complib';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';
import Trans from '~/components/Trans.vue';
const { t } = useTrans();

defineProps({
  keypath: {
    type: String,
    required: false,
    default: 'shop.productList.excludingVAT',
  },
  label: {
    type: String,
    required: false,
    default: 'shop.shippinghint.label',
  },
  hint: {
    type: String,
    required: false,
    default: 'shop.productList.shippingHint',
  },
  tag: {
    type: String,
    required: true,
  },
  classes: {
    type: String,
    required: true,
  },
});
</script>
